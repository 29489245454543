import { usePortalQuery } from "../components/usePortalQuery";

import { Loading } from "therese";

import { address } from "../utils/address";
import MessagesBase from "./Messages/Messages";

function Messages() {
  const { data, isLoading } = usePortalQuery<IAdminProperty>("agents/property");

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MessagesBase
      showMarkUnread
      title={data?.address ? address.toDisplay(data.address) : ""}
      nested
    />
  );
}

export default Messages;
