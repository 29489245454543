import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { parseSaleID } from "./utils/parseSaleID";

import BaseLayout from "./Layout";
import { agentPaths } from "./paths";

function Layout() {
  const { pathname } = useLocation();
  const [sale_id, setSale_id] = useState<undefined | number>(undefined);
  useEffect(() => {
    setSale_id(parseSaleID(pathname));
  }, [pathname]);

  return <BaseLayout paths={agentPaths} rootPath="/" pathsSuffix={sale_id} />;
}

export default Layout;
