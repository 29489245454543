import { useQuery } from "react-query";
import { ICMSTasksResponse } from "../components/useTasks";

import redaxios from "redaxios";

// used in admin tasks, replacing slugToName hardcodings
const useGetTaskName = () => {
  const { data } = useQuery<ICMSTasksResponse>(
    ["cms/tasks"],
    async () => {
      const resp = await redaxios.get<ICMSTasksResponse>(`/api/cms/tasks`);
      return resp.data;
    },
    {
      staleTime: 3600000,
    }
  );

  return (slug: string) =>
    data?.data.find((task) => task.slug === slug)?.displayName || "";
};

export default useGetTaskName;
