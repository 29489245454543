import { lazy } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import { Loading, MenuItem, MenuItemActiveClassName } from "therese";

import { SplitView } from "../../components/SplitView";
import { NavLink } from "../../components/NavLink";
import { usePortalQuery } from "../../components/usePortalQuery";

import { address } from "../../utils/address";

import { AgentView } from "../../components/AgentLayout/AgentLayout";
import cx from "classnames";

import { ReactComponent as CircleCheck } from "symbols/circle_check.svg";
import { ReactComponent as Lock } from "symbols/lock.svg";

import * as styles from "./styles.module.css";
import useGetTaskName from "../../utils/useGetTaskName";
import { slugToName } from "../../utils/slugToName";

const Overview = lazy(() => import("./Overview/Overview"));
const Entry = lazy(() => import("./Entry/Entry"));

function Tasks() {
  return (
    <Routes>
      <Route
        path=""
        element={
          <AgentView width="full">
            <Overview />
          </AgentView>
        }
      />
      <Route path=":slug" element={<TaskEntryLayout />}>
        <Route path="" element={<Entry />} />
      </Route>
    </Routes>
  );
}

function TaskEntryLayout() {
  const { data, isLoading } = usePortalQuery<IAdminTasks>("agents/tasks");
  const { data: property, isLoading: isPropertyLoading } =
    usePortalQuery<IAdminProperty>("agents/property");
  const getTaskName = useGetTaskName();

  const { slug } = useParams();

  if (isLoading || isPropertyLoading) {
    return <Loading />;
  }

  return (
    <SplitView title={address.toDisplay(property!.address)} nested>
      {data?.tasks.map((task) => {
        return (
          <MenuItem
            key={task.slug}
            as={NavLink}
            to={`../${String(task.slug)}`}
            className={cx({
              [MenuItemActiveClassName]: slug === task.slug,
            })}
            badge={
              task.status === "completed" ? (
                <CircleCheck className={styles.checkIcon} />
              ) : task.status === "locked" ? (
                <Lock className={styles.lockIcon} />
              ) : undefined
            }
          >
            {getTaskName(task.slug) || slugToName[task.slug]}
          </MenuItem>
        );
      })}
    </SplitView>
  );
}

export default Tasks;
