import { Dropdown, Card, CardSection, InputCheckbox, VStack } from "therese";

import { usePreferences, usePreferencesMutation } from "../usePreferences";
import { agentPhaseFilter } from "../../utils/phaseText";

export const AgentPhaseFilter = () => {
  const { data: preferences } = usePreferences();
  const { mutate } = usePreferencesMutation();

  const phases = preferences?.phase || [];

  return (
    <Dropdown title="Faser">
      <Card as="form">
        <CardSection>
          <VStack spacing="xxs">
            {agentPhaseFilter.items.map((item) => {
              const checked = phases.includes(item.value);
              return (
                <InputCheckbox
                  key={item.label}
                  checked={checked}
                  {...item}
                  onChange={(event) => {
                    event.stopPropagation();

                    const newValues = [...phases];

                    if (!checked) {
                      newValues.push(event.target.value);
                    } else {
                      const idx = newValues.indexOf(item.value);
                      newValues.splice(idx, 1);
                    }

                    mutate({ ...preferences, phase: newValues });
                  }}
                />
              );
            })}
          </VStack>
        </CardSection>
      </Card>
    </Dropdown>
  );
};
