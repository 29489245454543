import { useQueryClient } from "react-query";
import { usePortalQuery } from "./usePortalQuery";
import { usePreferences } from "./usePreferences";

export interface IPagination extends IAdminPropertiesPagination {
  limit: number;
}

interface IUsePropertiesResults {
  properties: IAdminProperty[];
  pagination: IPagination;
}

const LIMIT = 20;
function useProperties() {
  const { data: preferences } = usePreferences();
  const client = useQueryClient();

  const params = {
    phases: preferences?.phase || [],
    limit: LIMIT,
    offset: preferences?.offset || 0,
    search: preferences?.filter || "",
  };

  return usePortalQuery<IAdminProperties, unknown, IUsePropertiesResults>(
    ["agents/properties", { params }],
    {
      keepPreviousData: true,
      select: (data) => {
        return { ...data, pagination: { ...data.pagination, limit: LIMIT } };
      },
      onSuccess: ({ properties }) => {
        properties.forEach((p) => {
          client.setQueryData(
            ["agents/property", { params: { sale_id: p.id } }],
            (old) => old || p
          );
        });
      },
    }
  );
}

export { useProperties };
