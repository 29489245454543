import { createRoot } from "react-dom/client";
import { Root } from "./Root";

import App from "./App.agent";
import { Suspense } from "react";
import { LoadingFullScreen } from "./components/LoadingFullScreen";
import { BrowserRouter } from "react-router-dom";
import { FocusHandler } from "./components/FocusHandler";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Root title={SITE_NAME}>
    <Suspense fallback={<LoadingFullScreen />}>
      <BrowserRouter>
        <FocusHandler />
        <App />
      </BrowserRouter>
    </Suspense>
  </Root>
);
