const slugToName: Record<string, string> = {
  /* from user portal - tasks */
  "sign-onboarding": "Signer oppdragsavtale",
  "confirm-initial-meeting": "Avtal besøk av megler",
  "initial-notifications": "Sett varslingsinnstillinger",
  "set-photographer-date": "Avtal møte med fotograf",
  "set-valuer-date": "Avtal møte med takstperson",
  "form-questionnaire": "Fyll ut info om din bolig",
  "sign-declaration": "Informasjon om egenerklæring",
  "sign-deed": "Signer skjøte",
  "guide-photo": "Klargjør boligen til fotografering",
  "form-energy": "Fyll ut energimerking",
  "approve-photos": "Se over bildene",
  "approve-prospect": "Godkjenn salgsoppgave",
  "approve-valuation": "Godkjenn takstrapport",
  "approve-ad": "Godkjenn Finn-annonse",
  "guide-showing": "Klargjør boligen til visning",
  "form-information-viewing": "Viktig info før visning",
  "guide-prepare-bidding": "Forbered deg til budrunde",
  "sign-sale-contract": "Signer kjøpekontrakt (selger)",
  "sign-sale-contract-buyer": "Signer kjøpekontrakt (kjøper)",
  "sign-settlement": "Fyll ut oppgjørsinstruks",
  "guide-deed": "Signer skjøte",
  "guide-takeover": "Forbered deg til overtakelse",
  "guide-showing-person": "Forbered deg til visning",
  "guide-takeover-buyer": "Forbered deg til overtakelse",
  "sign-money-laundering": "Bestilling av verdivurdering",
  "register-new-address": "Flyttemelding",
  "free-insurance": "To måneder gratis bolig-, innbo og flytteforsikring",
  "free-insurance-new": "To måneder gratis bolig-, innbo og flytteforsikring",

  /* from user portal - events */
  "event-initial-meeting": "Besøk av megler",
  "event-photographer": "Besøk av fotograf",
  "event-valuer": "Besøk av takstperson",
  "event-takeover": "Overtakelse",

  /* from old agent portal */
  "sign-sale": "Signer oppdragsavtale",
  "set-viewing-date": "Sett visningsdato",
  "form-salgsoppgave": "Fyll ut salgsoppgave",
  "documentation-updated": "Dokumentasjon oppdatert",
  "publish-finn": "Publiser Finn-annonse (varsel SMS)",
  "event-showing-1": "Visning 1",
  "event-showing-2": "Visning",
};

export { slugToName };
