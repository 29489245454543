import { useEffect, useState } from "react";

const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    setDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setDebouncing(false);
    }, delay);

    return () => {
      setDebouncing(false);
      clearTimeout(handler);
    };
  }, [value, delay]);

  return {
    value: debouncedValue,
    debouncing,
  };
};

export default useDebouncedValue;
